import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HelpersService } from './helpers.service';
import { HttpQuery } from './http-query';

export interface BaseExtras {
  qt_id?: number;
  asmd_id?: number;
  total_bobot?: number;
  total_capaian?: number;
  criteria?: string;
  color?: string;
  total_skor?: number;
  indek_skor?: number;
  pengesahan?: string;
  pengesahan_ai?: string;
  pengesahan_rtm?: string;
}

export interface BaseAPI {
  length: number;
  data: [];
  extra?: BaseExtras;
}

@Injectable({
  providedIn: 'root',
})
export class BaseHttpService<T> {
  constructor(public http: HttpClient, public helper: HelpersService) {}

  base_url = '/api';
  endpoint: string;
  url() {
    return [this.base_url, this.endpoint].join('/');
  }
  url_id(id) {
    return [this.base_url, this.endpoint, id].join('/');
  }
  url_rel(id, relation) {
    return [this.base_url, this.endpoint, id, relation].join('/');
  }
  url_custom(c) {
    return [this.base_url, this.endpoint, c].join('/');
  }
  url_array(c: string[]) {
    const base_array = [this.base_url, this.endpoint];
    c.map((a) => base_array.push(a));
    return base_array.join('/');
  }
  getAll(query: HttpQuery) {
    const url = this.url();
    const params = this.helper.generateParams(query);
    return this.http.get<BaseAPI>(url, { params });
  }
  getWhere(query: HttpQuery, where) {
    const url = this.url_custom('filter');
    const params = this.helper.generateParams(query);
    return this.http.post<BaseAPI>(url, where, { params });
  }
  search(query: HttpQuery, where) {
    const url = this.url_custom('search');
    const params = this.helper.generateParams(query);
    return this.http.post<BaseAPI>(url, where, { params });
  }
  getById(id: string, query: HttpQuery = {}) {
    const url = this.url_id(id);
    const params = query ? this.helper.generateParams(query) : null;
    return this.http.get<T>(url, { params });
  }

  insert(data: any) {
    const url = this.url();
    return this.http.post<T>(url, data);
  }

  updateById(id: string, data: any) {
    const url = this.url_id(id);
    return this.http.put<T>(url, data);
  }

  removeById(id: string) {
    const url = this.url_id(id);
    return this.http.delete<T>(url);
  }
  removeAllQuestioner(id: string) {
    const url = this.url_array([id, 'clear']);
    return this.http.delete<T>(url);
  }
  removeAll() {
    const url = this.url();
    return this.http.delete<T>(url);
  }
  getFilter(query: HttpQuery, where){
    const url = [this.base_url, 'view', 'questioner', 'filter'].join('/');
    const params = this.helper.generateParams(query);
    return this.http.post<BaseAPI>(url, where, { params });
  }
}
