import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { AuthService } from './auth.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { HttpQuery } from './http-query';
import { FormGroup } from '@angular/forms';
import { PaginationObserver } from './pagination';
import { ToolbarObserver } from './toolbar';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { Penggunas } from '../backend/pages/pengguna/penggunas';
import { ShortenPipe, StripTagsPipe, TrimPipe } from 'ngx-pipes';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { environment } from 'src/environments/environment';

export interface Page {
  pageIndex: number;
  pageSize: number;
}
@Injectable({
  providedIn: 'root',
})
export class HelpersService {
  constructor(
    private sb: MatSnackBar,
    private auth: AuthService,
    private sanitizer: DomSanitizer,
    private shorten: ShortenPipe,
    private strip: StripTagsPipe,
    private trim: TrimPipe
  ) {
    // this.initPagination();
  }

  toolbar$ = new BehaviorSubject<ToolbarObserver>({
    pageTitle: 'Dashboard',
    hiddenBtnSearch: false,
    hiddenBtnSidebar: false,
  });
  search$ = new BehaviorSubject('');
  showSidebar$ = new BehaviorSubject(false);
  hidePagination$ = new BehaviorSubject(false);
  pagination$: BehaviorSubject<PaginationObserver>;
  pageChange$ = new BehaviorSubject<any>({});
  pageLength$: BehaviorSubject<number>;
  firstPage$: BehaviorSubject<boolean>;
  pengesahanPagination$ = new BehaviorSubject<PaginationObserver>({
    hide: false,
    length: 0,
    pageIndex: 0,
  });
  chartLogColorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5'],
  };
  qpStatus$ = new BehaviorSubject<string>('');
  filter$ = new BehaviorSubject<string>('');
  filterResult$ = new BehaviorSubject<any>({});
  isLoading$ = new BehaviorSubject<boolean>(false);
  exportBtn$: BehaviorSubject<boolean>;
  menuRefresh$ = new BehaviorSubject<boolean>(false);
  settings$ = new BehaviorSubject<boolean>(false);
  angularEditorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: false,
    height: '200',
    minHeight: '86',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'heading',
        'fontName',
        'justifyLeft',
        'strikeThrough',
        'subscript',
        'superscript',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
      ],
    ],
  };

  percentagePattern = /^(\d{0,2}(\.\d+)?|100(\.0+)?)$/;

  bulanList = [
    { no: 1, name: 'Januari' },
    { no: 2, name: 'Februari' },
    { no: 3, name: 'Maret' },
    { no: 4, name: 'April' },
    { no: 5, name: 'Mei' },
    { no: 6, name: 'Juni' },
    { no: 7, name: 'Juli' },
    { no: 8, name: 'Agustus' },
    { no: 9, name: 'September' },
    { no: 10, name: 'Oktober' },
    { no: 11, name: 'November' },
    { no: 12, name: 'Desember' },
  ];

  getLastDate(year: number, month: number) {
    var result = new Date(year, month, 0).getDate();
    return result;
  }

  static toExportFileName(filename: string): string {
    return `${filename} [${moment().format('DD-MM-YYYY hh.mm.ss')}].xlsx`;
  }

  padleft(c) {
    return `${c * 16}px`;
  }
  sbError(
    message: string,
    action = null,
    autoClose: boolean | MatSnackBarConfig = true
  ) {
    return this.sb.open(
      message,
      action ? action : 'Galat',
      autoClose ? { duration: 5000, panelClass: ['dishub-snackbar'] } : {}
    );
  }
  sbSuccess(message: string, action = null): void {
    this.sb.open(message, action ? action : 'Sukses', {
      duration: 5000,
      panelClass: ['dishub-snackbar'],
    });
  }
  parseUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.setHttp(url));
  }
  setHttp(link) {
    if (link && link.search(/^http[s]?\:\/\//) == -1) {
      link = 'http://' + link;
    }
    return link;
  }
  assetUrl(path: string) {
    return `${environment.host}/${path}`;
  }
  apiUrl(path: string) {
    return `${environment.host}/api/${path}`;
  }
  shortIt(text: string) {
    if (!text) return '-';
    return this.trim.transform(
      this.shorten.transform(this.strip.transform(text), 36, '...')
    );
  }
  generateParams(q: HttpQuery): HttpParams {
    let params = new HttpParams();
    if (q.limit > -1) {
      params = params.append('limit', q.limit.toString());
    }
    if (q.offset > -1) {
      params = params.append('offset', q.offset.toString());
    }
    if (q.sort) {
      params = params.append('sort', q.sort);
    }
    if (q.search) {
      params = params.append('search', q.search.toString());
    }
    if (q.include) {
      params = params.append('include', q.include);
    }
    if (q.where) {
      params = this.toHttpParams(q.where, params, 'where');
    }
    return params;
  }
  parseSort(active, direction) {
    return `${direction === 'asc' ? '' : '-'}${active}`;
  }
  generateError(errors) {
    let errMessage = '';
    if (errors) {
      errors.forEach((e) => {
        errMessage += `${e.param}: ${e.msg}. `;
      });
    }
    return errMessage;
  }
  toHttpParams(obj, params, type) {
    const keys = Object.keys(obj);
    if (keys.length) {
      keys.forEach((key) => {
        params = params.append(`${type}[${key}]`, obj[key]);
      });
    }
    return params;
  }
  currentUser(): null | Penggunas {
    const ls = localStorage.getItem(this.auth.localUser);
    return ls ? JSON.parse(ls) : null;
  }
  isInvalid(form: FormGroup, control: string): boolean {
    return (
      form.get(control).invalid &&
      (form.get(control).dirty || form.get(control).touched)
    );
  }
  exportAsExcelFile(json: any[], filename: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, HelpersService.toExportFileName(filename), {
      bookType: 'xlsx',
      type: 'binary',
    });
  }
  generateInterfaceFromObject(obj) {
    const keys = Object.keys(obj);
    const o = keys.map((k) => {
      return `${k}: ${typeof obj[k]};`;
    });
    console.table(o);
  }
}
