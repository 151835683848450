import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'backend',
    loadChildren: () =>
      import('./backend/backend.module').then((m) => m.BackendModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./frontend/pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'forget-password',
    loadChildren: () => import('./frontend/pages/forget-password/forget-password.module').then((m) => m.ForgetPasswordModule),
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./frontend/pages/reset-password/reset-password.module').then( m => m.ResetPasswordModule)
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./frontend/pages/error-page/error-page.module').then(
        (m) => m.ErrorPageModule
      ),
  },
  {
    path: 'under-construction',
    loadChildren: () =>
      import('./frontend/pages/underconstruction-page/underconstruction-page.module').then(
        (m) => m.UnderconstructionPageModule
      ),
  },
  {
    path: '**',
    redirectTo: 'error',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
