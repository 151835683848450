import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { LoginForm } from '../frontend/pages/login/login.component';
export interface Login {
  user: any;
  token: string;
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  localToken = 'x-sieva-token';
  localUser = 'x-sieva-user';
  constructor(
    private http: HttpClient,
    private router: Router
    ) {
      this.token = localStorage.getItem(this.localToken);
    }
    token: string;
    isLoggedIn(): boolean {
      return localStorage.getItem(this.localUser) ? true : false;
    }
    login(form: LoginForm) {
      const { username, password, rememberMe, token } = form;
      const auth = 'Basic ' + btoa(`${username}:${password}`);
      const headers = new HttpHeaders().append('Authorization', auth);
      const url = '/auth/login';
      return this.http.post<Login>(url, { rememberMe, token}, {headers});
    }
    logout(): void {
      localStorage.removeItem(this.localUser);
      localStorage.removeItem(this.localToken);
      localStorage.removeItem('req-data-pendukung-tab');
      localStorage.removeItem('kriteria-tab');
      this.router.navigate(['/']);
    }
    forgotPassword(nipOrEmail: string ){
      const body = { credential: nipOrEmail };
      return this.http.post<any>('/auth/forgot-password', body );
    }
    restorePassword(body: any){
      return this.http.post<any>('/auth/restore-password', body);
    }
    logoutLog() {
      const url = '/auth/logout';
      return this.http.get<any>(url);
    }
}
