import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PengaturanSitus } from './pengaturan-situs';
import { HelpersService } from 'src/app/services/helpers.service';
import { BaseHttpService } from 'src/app/services/base-http.service';

@Injectable({
  providedIn: 'root'
})
export class PengaturanSitusService extends BaseHttpService<PengaturanSitus> {
  constructor(http: HttpClient, helper: HelpersService) {
    super(http, helper);
    this.endpoint = 'site';
  }

  updateById(site_id, data){
    const url = this.url_array([site_id]);
    return this.http.post<any>(url, data);
  }
}
