import { BrowserModule, Title, Meta } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeId from '@angular/common/locales/id';
import localeIdExtra from '@angular/common/locales/extra/id';
import { SharedModule } from './shared.module';
import { HttpInterceptors } from './interceptors';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { NgxUploadModule } from '@wkoza/ngx-upload';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ShortenPipe, StripTagsPipe, TrimPipe } from 'ngx-pipes';

export function getDatepickerConfig(): BsDatepickerConfig {
  return Object.assign(new BsDatepickerConfig(), {
    containerClass: 'theme-blue',
    adaptivePosition: true,
    dateInputFormat: 'DD-MM-YYYY'
  });
}
registerLocaleData(localeId, 'id', localeIdExtra);
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    BsDatepickerModule.forRoot(),
    CollapseModule.forRoot(),
    NgxUploadModule.forRoot(),
    TypeaheadModule.forRoot(),
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    HttpInterceptors,
    Title,
    Meta,
    ShortenPipe,
    StripTagsPipe,
    TrimPipe,
    { provide: BsDatepickerConfig, useFactory: getDatepickerConfig }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
