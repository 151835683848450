import { Component, OnDestroy } from '@angular/core';
import { PengaturanSitus } from './backend/pages/pengaturan/pengaturan-situs/pengaturan-situs';
import { PengaturanSitusService } from './backend/pages/pengaturan/pengaturan-situs/pengaturan-situs.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';
import { HelpersService } from './services/helpers.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  private unsubs = new Subject();
  constructor(private srv: PengaturanSitusService, private title: Title, private meta: Meta, private helper: HelpersService) {
    this.updateSetting();
    this.helper.settings$.pipe(takeUntil(this.unsubs)).subscribe(val => {
      if (val) {
        this.updateSetting();
      }
    });
  }
  setting: PengaturanSitus = {};
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  ngOnDestroy(){
    this.unsubs.next();
    this.unsubs.complete();
  }
  updateSetting(){
    this.srv.getAll({ limit: 0, offset: 0}).pipe(takeUntil(this.unsubs)).subscribe((settings) => {
      this.setting = settings[0];
      this.title.setTitle(this.setting.site_name);
      this.meta.updateTag({ name: 'description', content: this.setting.site_desc });
      this.changeIcon(this.setting.site_favicon);
    });
  }
  changeIcon(url) {
    this.favIcon.href = url;
  }
}
